import React, { useMemo, useState } from "react";
import HeaderBar from "components/docs/layout/HeaderBar";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import StaticFancyPageContainer from "components/docs/shared-components/StaticFancyPageContainer";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import styled from "styled-components";
import GetStartedLeftSidebar from "components/docs/get-started/GetStartedLeftSidebar";
import { Link } from "gatsby";
import LINKS from "components/docs/navigation/links";
import GetStartedOnboardingStep from "components/docs/get-started/GetStartedOnboardingStep";
import GlowButton from "components/generic/Button/GlowButton";
import { palette, spectrum } from "styles/theme";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import TwoColumnCenteredSection, {
  ContentContainer,
} from "components/docs/sections/TwoColumnCenteredSection";
import { APICategory, deepFindFirstValueForKey } from "@merge-api/merge-javascript-shared";
import { CategoryPrimaryModelInfo } from "types/types";
import MultiOptionCodeBlock, {
  OptionSelectorsDropdownContainer,
  OptionConfig,
} from "components/docs/shared-components/code-block/MultiOptionCodeBlock";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import CodeBlock, { Header } from "components/docs/shared-components/code-block/CodeBlock";
import { PaddingElement } from "components/docs/shared-components/code-block/RawCodeBlock";
import { Cpu } from "lucide-react";
import { StaticImage } from "gatsby-plugin-image";
import useCategorySchemas from "components/docs/hooks/useCategorySchemas";
import { getSampleSchemaFromDocument } from "components/docs/OpenAPISchemaVisualizer";
import {
  GettingStartedSidebarContentBox,
  GettingStartedStickyRightSidebar,
} from "components/docs/shared-components/StickyRightSidebar";
import GetStartedURLBox from "components/docs/get-started/GetStartedURLBox";
import DeprecatedH2 from "components/deprecated/DeprecatedH2";
import DeprecatedH3 from "components/deprecated/DeprecatedH3";
import DeprecatedH4 from "components/deprecated/DeprecatedH4";
import AsideBarContent from "components/generic/AsideBarContent";

const StyledHeaderBar = styled(HeaderBar)`
  div {
    padding-bottom: 0px;
  }
`;

const SpacedTwoColumnCenteredSection = styled(TwoColumnCenteredSection)`
  margin-bottom: 64px;

  ${ContentContainer} {
    box-shadow: 0px 6px 30px -2px rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    // Remove second column's margin regardless of width of browser
    > div:last-of-type {
      margin-bottom: 0;
    }

    .dropdown-toggle {
      text-align: start;
      word-wrap: break-word;
      white-space: normal;
    }
  }
`;

const LeftColumn = styled.div.attrs({
  className: "mt-6 ml-6 mr-3 pr-2",
})`
  &&& {
    .deprecatedh3,
    .deprecatedh4 {
      margin-top: 16px;
    }
    p:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

const HeaderDropdownContainer = styled.div`
  background-color: transparent;
  && {
    button {
      margin: -8px 0px 4px -9px;
      padding: 8px;
      background-color: transparent !important;
      color: inherit !important;
      border: none;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent !important;
        color: inherit !important;
        border: none;
      }
    }
  }
`;

const RightColumn = styled.div`
  background: #07193d;
  height: 100%;
  display: flex;
  flex-direction: column;

  // Matches SpacedTwoColumnCenteredSection's {ContentContainer}
  @media (min-width: 992px) {
    border-top-right-radius: 12px;
  }
  @media (max-width: 991px) {
    border-bottom-left-radius: 12px;
  }
  border-bottom-right-radius: 12px;

  ${OptionSelectorsDropdownContainer} {
    margin-right: 0px;
  }

  ${Header} {
    box-shadow: 0px 1px 0px #022c7c;
  }

  #language-dropdown {
    padding-right: 0px;
  }

  && {
    pre {
      margin: 16px 12px;
      border-radius: 8px;
    }

    hr {
      border-top: 1px solid #022c7c;
    }
  }
`;

const RightColumnParagraph = styled.p.attrs({ className: "px-3" })`
  &&& {
    padding-bottom: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  color: ${palette.gray};
`;

const RightColumnH2 = styled(DeprecatedH2).attrs({ className: "px-3" })`
  &&& {
    margin: 8px 0px;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.5;
  }

  color: ${palette.empty};
`;

const RightColumnButton = styled(Button)`
  &&& {
    background: ${palette.indigo};
    border: 1px solid ${palette.indigo};
    align-self: flex-start;
    font-weight: 500;

    :hover,
    :active {
      background: ${palette.indigoHover};
    }
  }
`;

const RightColumnPre = styled.pre`
  background: #00102f;
  color: ${spectrum.gray20};
  flex-grow: 1;
`;

const RightColumnResponseCodeBlock = styled(CodeBlock)`
  flex-grow: 1;
  background: #00102f;
  @media (max-width: 991px) {
    border-bottom-left-radius: 12px;
  }
  border-bottom-right-radius: 12px;

  && {
    pre {
      height: 100%;
      margin: 0;
      padding: 10px;
    }
  }

  ${PaddingElement} {
    min-width: 0;
  }
`;

const optionConfigs: Array<OptionConfig<APICategory>> = (
  Object.keys(APICategory) as APICategory[]
).map((category) => ({
  key: category,
  name: CategoryPrimaryModelInfo[category].dropdownText,
  language: "bash",
  text: ` $ curl https://api.merge.dev/api/${CategoryPrimaryModelInfo[category].endpointPath} \\\

     -H "Authorization: Bearer 555555555555555555555555555555555555555555555555555555" \\\

     -H "X-Account-Token: 333333333333333333333333333333333333333333333333333333"`,
}));

const PostmanBox = styled(GettingStartedSidebarContentBox)`
  display: flex;
  flex-direction: column;
  background: ${spectrum.yellow0};
  margin-bottom: 32px;

  > span {
    display: block;
    margin-bottom: 16px;
  }
`;

const SDKsBox = styled(GettingStartedSidebarContentBox)`
  background: ${spectrum.indigo0};
  margin-bottom: 24px;

  svg {
    color: ${palette.indigo};
  }
`;

const MobileOnlyContent = styled.div`
  margin-top: -32px;

  @media (min-width: 992px) {
    display: none;
  }
`;

const RightSidebarContent = () => (
  <>
    <PostmanBox>
      <DeprecatedH3>
        <StaticImage
          src="../../assets/images/docs/get-started/unified-api/postman.png"
          alt="Postman Logo Image"
          quality={100}
          placeholder="dominantColor"
          layout="constrained"
          loading="eager"
          width={24}
        />{" "}
        Postman
      </DeprecatedH3>
      <span>
        Visit our <b>Postman Collections</b> to test our Unified API.
      </span>
      <a
        target="_blank"
        href="https://www.postman.com/mergeapi/workspace/merge-public-workspace/overview"
        rel="noreferrer"
      >
        <span aria-label="Run in Postman" role="button">
          <img src="https://run.pstmn.io/button.svg" alt="" />
        </span>
      </a>
    </PostmanBox>
    <AsideBarContent
      Icon={<Cpu />}
      content={
        <span>
          Use our <Link to={LINKS.SDKS.linkTo}>{LINKS.SDKS.text}</Link> to integrate with our
          Unified API even faster.
        </span>
      }
      title={"SDKs"}
    />
  </>
);

const getSampleSchema = (
  categorySchemas: ReturnType<typeof useCategorySchemas>,
  category: APICategory,
) => {
  const document = categorySchemas[category];
  const fullPath = CategoryPrimaryModelInfo[category].endpointPath;
  const categorySpecificPath = fullPath.substring(fullPath.lastIndexOf("/"));
  const getListSchema = document.paths[categorySpecificPath]?.get;
  const responseSchema = getListSchema?.responses
    ? deepFindFirstValueForKey(getListSchema.responses, "schema")
    : undefined;
  const sampleSchema = responseSchema && getSampleSchemaFromDocument(responseSchema, document);
  return sampleSchema;
};

const UnifiedAPIPage = () => {
  const [selectedCategory, setSelectedCategory] = useState<APICategory>(APICategory.hris);
  const [isResponseShowing, setIsResponseShowing] = useState<boolean>(false);
  const categorySchemas = useCategorySchemas();
  const sampleSchemas: Partial<Record<APICategory, string | undefined>> = useMemo(
    () =>
      Object.fromEntries(
        (Object.keys(APICategory) as APICategory[]).map((category) => [
          category,
          getSampleSchema(categorySchemas, category) || "No content",
        ]),
      ),
    [categorySchemas],
  );

  return (
    <StaticFancyPageContainer
      leftSidebar={<GetStartedLeftSidebar />}
      rightSidebar={
        <GettingStartedStickyRightSidebar>
          <RightSidebarContent />
        </GettingStartedStickyRightSidebar>
      }
    >
      <DocsHelmet
        title="Merge — Unified API"
        description="Learn how to use Merge's features via our guided introductions."
      />

      <SmallWidthSection fadeBackground={false}>
        <StyledHeaderBar
          title="Unified API"
          subtitle={
            <>
              {" "}
              <p>
                You can pull data from and push data to third-party platforms via our{" "}
                <b>Unified API.</b>
              </p>
              <p>Try pulling data below:</p>
            </>
          }
          styling="top-level"
          showDivider={false}
        />
      </SmallWidthSection>

      <SpacedTwoColumnCenteredSection
        fadeBackground={false}
        columnA={
          <LeftColumn>
            <HeaderDropdownContainer>
              <DropdownButton
                title={`Pull ${CategoryPrimaryModelInfo[selectedCategory].modelName} Data`}
              >
                {optionConfigs.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    onClick={() => {
                      setSelectedCategory(option.key);
                      setIsResponseShowing(false);
                    }}
                  >
                    {option.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </HeaderDropdownContainer>
            <p>
              Send a GET request to the {CategoryPrimaryModelInfo[selectedCategory].pluralName}{" "}
              endpoint in our Unified API:
            </p>
            <GetStartedURLBox>
              <code>
                https://api.merge.dev/api/
                {CategoryPrimaryModelInfo[selectedCategory].endpointPath}
              </code>
            </GetStartedURLBox>
            <hr />
            <DeprecatedH3 className="mt-0">Authentication</DeprecatedH3>
            <p>
              The cURL command to the right demonstrates authentication of your request (via API key
              and account token).
            </p>
            <DeprecatedH4>API Key</DeprecatedH4>
            <p>
              Security token used to authenticate all your requests to Merge's Unified API. Get it
              from <Link to={LINKS.API_KEY_REFERENCE.linkTo}>your Dashboard</Link>.
            </p>
            <DeprecatedH4>Account Token</DeprecatedH4>
            <p>
              Identifying security token for the user you would like to make the API request for.
            </p>
            <p>
              Learn more in our{" "}
              <Link to={LINKS.AUTHENTICATION.linkTo}>{LINKS.AUTHENTICATION.text}</Link> guide.
            </p>
          </LeftColumn>
        }
        columnB={
          <RightColumn>
            <MultiOptionCodeBlock
              codeBlockName="Unified API Tester"
              hasLineNumbers={false}
              hideCopyButtonWithHeader
              isVerticallyPadded={false}
              hideOption
              optionConfigs={optionConfigs}
              selectedOptionKey={selectedCategory}
              setSelectedOptionKey={(selectedOptionKey) => {
                setSelectedCategory(selectedOptionKey);
                setIsResponseShowing(false);
              }}
            />
            <RightColumnParagraph>
              Mock API key and account token for illustrative purposes
            </RightColumnParagraph>
            <RightColumnButton className="mx-3" onClick={() => setIsResponseShowing(true)}>
              Send GET Request
            </RightColumnButton>
            <div>
              <hr className="mt-6 mb-0" />
              <RightColumnH2>Response</RightColumnH2>
              <hr className="my-0" />
            </div>
            {!isResponseShowing ? (
              <RightColumnPre className="m-0 p-3">
                Press &quot;Send GET Request&quot; button
              </RightColumnPre>
            ) : (
              <RightColumnResponseCodeBlock
                hasLineNumbers={false}
                hideCopyButtonWithHeader
                isVerticallyPadded={false}
                language="json"
                text={sampleSchemas[selectedCategory]}
              />
            )}
          </RightColumn>
        }
      />

      <SmallWidthSection fadeBackground={false}>
        <MobileOnlyContent>
          <RightSidebarContent />
        </MobileOnlyContent>
      </SmallWidthSection>

      <SectionDivider fadeBackground={false} />

      <SmallWidthSection fadeBackground={false}>
        <GetStartedOnboardingStep
          stepLabel="Next Up"
          stepTitle="In the next guide, create account tokens by linking an account&hellip;"
          stepContent={
            <>
              <p className="mb-6">
                Get data and account tokens for the Unified API by creating connections to
                third-party platforms in our Linked Account guide.
              </p>
              <Link to={LINKS.GET_STARTED_LINKED_ACCOUNT.linkTo}>
                <GlowButton
                  color={palette.black}
                  showChevron
                  borderRadius={6}
                  text="Go to Linked Account guide"
                />
              </Link>
            </>
          }
        />
      </SmallWidthSection>
    </StaticFancyPageContainer>
  );
};

export default UnifiedAPIPage;
